var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 4 },"wrapper-col":{ span: 18 }},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"当前项目","label-col":{ span: 8 },"wrapper-col":{ span: 12 }}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.msg.designCode}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"工程指令单编号","label-col":{ span: 8 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'contractId',
            {
              rules: [
                {
                  required: true,
                  message: '请输入',
                },
              ],
            }
          ]),expression:"[\n            'contractId',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入',\n                },\n              ],\n            }\n          ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"工程名称"}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.msg.projectName}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"情况说明"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'description',
          { rules:[{ required: true, message: '请输入' }]}
          ]),expression:"[\n          'description',\n          { rules:[{ required: true, message: '请输入' }]}\n          ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("附件")]),_c('div',{staticClass:"link-list"},[(_vm.fileList.length !== 0)?_c('a-checkbox-group',{attrs:{"value":_vm.selectedFileList},on:{"change":_vm.onSelectFile}},_vm._l((_vm.fileList),function(item){return _c('div',{key:item.id,staticClass:"link"},[_c('a-checkbox',{attrs:{"value":item.id}},[_c('a',{attrs:{"target":"_blank","download":"","href":item.completePath}},[_vm._v(_vm._s(item.name))])])],1)}),0):(_vm.fileList.length === 0 && _vm.id)?_c('div',{staticStyle:{"color":"#ccc"}},[_vm._v(" 无 ")]):_c('div',{staticStyle:{"color":"#ccc"}},[_vm._v(" 《工程签证单》 ")]),_c('div',{staticClass:"control-bar"},[_c('FileUpload',{on:{"uploaded":_vm.uploaded}},[_c('div',{staticClass:"control"},[_c('a-icon',{attrs:{"type":"upload"}})],1)]),_c('div',{staticClass:"control",on:{"click":_vm.deleteFile}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)],1)])],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),_c('a-button',{attrs:{"html-type":"submit","type":"primary"}},[_vm._v("提交")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }