<template>
  <div>
    <a-form :form="form" @submit="handleSubmit" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" :colon="false">
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="当前项目" class="default-highlight" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
            <a-input :disabled="true" :placeholder="msg.designCode"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="合同编号" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
            <a-input v-decorator="[
                            'contractId',
                            { rules: [{ required: true, message: '请输入' }] },
                          ]"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="工程名称" class="default-highlight">
            <a-input :disabled="true" :placeholder="msg.projectName"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="项目名称">
            <a-input v-decorator="[
                            'constructionName',
                            { rules: [{ required: true, message: '请输入' }] },
                          ]"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="发出时间" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
            <a-date-picker format="YYYY-MM-DD" v-decorator="[
                            'deliveryTime',
                            { rules: [{ required: true, message: '请选择' }] },
                          ]" style="width: 100%" />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="估价(万元)" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
            <a-input-number :formatter="$inputNumberFormat" v-decorator="[
                            'valuation',
                            { rules: [{ required: true, message: '请输入！' }] },
                          ]" style="width: 100%" />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="是否需要核价" :label-col="{ span: 12 }" :wrapper-col="{ span: 8 }">
            <a-radio-group v-decorator="[
                            'accountingPriceOption',
                            { rules: [{ required: true, message: '请选择' }] },
                          ]">
              <a-radio value="1"> 是 </a-radio>
              <a-radio value="0"> 否 </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="是否符合资金审计原则" :label-col="{ span: 12 }" :wrapper-col="{ span: 8 }">
            <a-radio-group v-decorator="[
                            'suitAudit',
                            { rules: [{ required: true, message: '请选择' }] },
                          ]">
              <a-radio value="1"> 是 </a-radio>
              <a-radio value="0"> 否 </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="是否需要经决会决策" :label-col="{ span: 12 }" :wrapper-col="{ span: 8 }">
            <a-radio-group v-decorator="[
                            'meetingComments',
                            { rules: [{ required: true, message: '请选择' }] },
                          ]">
              <a-radio value="1"> 是 </a-radio>
              <a-radio value="0"> 否 </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="情况说明">
            <a-input v-decorator="[
                            'description',
                            { rules: [{ required: true, message: '请输入' }] },
                          ]"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item>
            <span slot="label">分包事项申请</span>
            <div class="link-list">
              <a-checkbox-group :value="selectedFileList" @change="onSelectFile" v-if="fileList.length !== 0">
                <div class="link" v-for="item in fileList" :key="item.id">
                  <a-checkbox :value="item.id">
                    <a target="_blank" download :href="item.completePath">{{
                                          item.name
                                          }}</a>
                  </a-checkbox>
                </div>
              </a-checkbox-group>
              <div style="color: #ccc" v-else-if="fileList.length === 0 && id">
                无
              </div>
              <div style="color: #ccc; line-height: 1.5" v-else>
                《分包事项申请预算单》
              </div>

              <div class="control-bar">
                <FileUpload @uploaded="uploaded">
                  <div class="control">
                    <a-icon type="upload" />
                  </div>
                </FileUpload>
                <div class="control" @click="deleteFile">
                  <a-icon type="delete" />
                </div>
              </div>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
          <a-button html-type="submit" type="primary">提交</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>
<script>
import FileUpload from "@/components/file-upload";
import { saveAs } from "file-saver";

import {
  add,
  edit,
  fetchDetail,
  exportFile,
  downloadTemplate,
} from "@/api/epc/construction/subPackagedVisa";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: {
    msg: {
      type: Object,
      default: () => ({
        pid: "",
        designCode: "",
        projectName: "",
        stage: "",
      }),
    },
  },
  components: {
    FileUpload,
  },
  data() {
    return {
      stage: "epc_construction_subpackaged_visa",
      isView: false,

      form: this.$form.createForm(this),
      id: "",

      fileList: [],
      selectedFileList: [],

      fileType: "construction_subpackaged_visa", //文件导出相关
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
    fileTypeName() {
      return this.findSingleDictName("epc_file_export_type", this.fileType);
    },
  },

  mounted() {
    const { query } = this.$route;
    const { id, hid, isView } = query || {};

    this.id = id;
    this.hid = hid;
    this.isView = Boolean(isView) || false;

    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      fetchDetail(this.id).then((res) => {
        console.log(res);

        if (!res.id) return;

        this.form.setFieldsValue({
          deliveryTime: moment(res.deliveryTime),
          contractId: res.contractId,
          constructionName: res.constructionName,
          valuation: res.valuation,
          accountingPriceOption: res.accountingPriceOption,
          suitAudit: res.suitAudit,
          meetingComments: res.meetingComments,
          description: res.description,
        });

        if (res.attachmentList) {
          this.fileList = res.attachmentList;
        }
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const deliveryTime = values.deliveryTime.format("YYYY-MM-DD");
          delete values.deliveryTime;
          // 如果没有id ，则新增
          if (!this.id) {
            console.log("新增");

            add({
              ...values,
              deliveryTime,
              pid: this.msg.pid,
              designCode: this.msg.designCode,
              projectName: this.msg.projectName,
              attachments: this.fileList,
              type: this.stage,
            }).then(() => {
              this.$close(this.$route.path);
            });
          } else {
            edit({
              ...values,
              id: this.id,
              pid: this.msg.pid,
              historyId: this.hid,
              designCode: this.msg.designCode,
              projectName: this.msg.projectName,
              attachmentList: this.fileList,
              type: this.stage,
            }).then(() => {
              this.$close(this.$route.path);
            });
          }
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];
    },

    exportForm() {
      exportFile({ id: this.id, fileExportType: this.fileType })
        .then((blob) => {
          saveAs(blob, `${this.msg.projectName}_${this.fileTypeName}.docx`);
        })
        .catch();
    },
    download() {
      downloadTemplate(this.fileType)
        .then((blob) => {
          saveAs(blob, `${this.fileTypeName}模版.docx`);
        })
        .catch();
    },
  },
};
</script>

<style lang="less" scoped>
.center {
  margin-top: 80px;
}
</style>
