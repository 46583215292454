<template>
  <div>
    <Pane :bottom="0"
          :showRemarks="false">
      <a-tabs v-model="activeKey"
              :tabBarStyle="{
          margin: '0',
        }">
        <a-tab-pane v-for="item in tabList"
                    :key="item.key"
                    :tab="item.name"
                    :disabled="item.disabled"></a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <ExportDownload :isView="isView"
                        :url="imgUrl"
                        @exportForm="exportForm"
                        @download="download" />
      </template>
    </Pane>

    <div class="container">
      <Visa ref="visaRef"
            v-if="activeKey === '0' && pData.projectName"
            :msg="pData"></Visa>
      <PriceApp ref="priceAppRef"
                v-if="activeKey === '1' && pData.projectName"
                :msg="pData"></PriceApp>
      <Confirm ref="confirmRef"
               v-if="activeKey === '2' && pData.projectName"
               :msg="pData"></Confirm>
    </div>
  </div>
</template>

<script>
import ExportDownload from "@/views/epc/components/ExportDownload";
import Visa from "./components/Visa"; //分包签证事项申请
import PriceApp from "./components/PriceApp"; //工程核价审批表
import Confirm from "./components/Confirm"; //工程签证审批表

import { fetchDetail } from "@/api/epc";

export default {
  name: "construcVisa",
  components: {
    ExportDownload,
    Confirm,
    Visa,
    PriceApp,
  },
  data() {
    return {
      imgUrl: require("@/assets/epc/approvalFlow/4-7subpackage.png"),
      activeKey: "0",
      tabList: [
        { key: "0", name: "分包签证事项申请", disabled: false },
        { key: "1", name: "工程核价单审批表", disabled: false },
        { key: "2", name: "工程签证审批表", disabled: false },
      ],

      form: this.$form.createForm(this),
      pid: "", // 项目id
      pData: {},

      isView: false,
    };
  },
  watch: {
    activeKey: {
      handler(newV) {
        if (newV === "0") {
          this.imgUrl = require("@/assets/epc/approvalFlow/4-7subpackage.png");
        } else if (newV === "1") {
          this.imgUrl = require("@/assets/epc/approvalFlow/4-8subpackage.png");
        } else if (newV === "2") {
          this.imgUrl = require("@/assets/epc/approvalFlow/4-9subpackage.png");
        }
      },
      immediate: true,
    },
  },

  mounted() {
    const { query } = this.$route;
    const { activeKey, pid, id, isView } = query || {};

    if (!pid) {
      return;
    }
    this.activeKey = activeKey || "0";
    this.pid = pid;
    this.isView = Boolean(isView) || false;

    if (id) {
      this.setTab();
    }
    this.getProjectInfo();
  },
  methods: {
    setTab() {
      this.tabList.forEach((item) => {
        if (item.key !== this.activeKey) {
          item.disabled = true;
        }
      });
    },
    getProjectInfo() {
      fetchDetail(this.pid).then((res) => {
        this.pData = {
          pid: this.pid,
          designCode: res.designCode,
          projectName: res.name,
        };
      });
    },

    download() {
      switch (this.activeKey) {
        case "0":
          this.$refs.visaRef.download();
          break;
        case "1":
          this.$refs.priceAppRef.download();
          break;
        case "2":
          this.$refs.confirmRef.download();
          break;
      }
    },
    exportForm() {
      switch (this.activeKey) {
        case "0":
          this.$refs.visaRef.exportForm();
          break;
        case "1":
          this.$refs.priceAppRef.exportForm();
          break;
        case "2":
          this.$refs.confirmRef.exportForm();
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  background-color: #fff;
  border: 1px solid #ccc;
  div {
    padding: 5px;
    &:hover {
      background-color: #1890ff;
      color: #fff;
      cursor: pointer;
    }
    span {
      display: block;
      text-align: center;
    }
  }
  div:last-child {
    margin-bottom: 0;
  }
}

.container {
  background-color: #fff;
  padding: 24px 20px;
}
</style>
