import request from '@/api/request'
import download from '@/api/download'

// 分包签证
export function add(data) {
  return request({
      url: '/market-service/epc/construction/subPackagedVisa/save',
      method: 'post',
      data
  })
}

export function edit(data) {
  return request({
      url: '/market-service/epc/construction/subPackagedVisa/modify',
      method: 'post',
      data,
  })
}

export function fetchDetail(params) {
  return request({
      url: `/market-service/epc/construction/subPackagedVisa/query/${params}`,
  })
}

// 下载模版
export function downloadTemplate(params) {
  return download({
      url: `/market-service/epc/construction/subPackagedVisa/export/template?fileExportType=${params}`,
  })
}
// 导出
export function exportFile(data) {
  return download({
      url: `/market-service/epc/construction/subPackagedVisa/export`,
      data,
  })
}