var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 4 },"wrapper-col":{ span: 18 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"当前项目","label-col":{ span: 8 },"wrapper-col":{ span: 12 }}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.msg.designCode}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"合同编号","label-col":{ span: 8 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'contractId',
                          { rules: [{ required: true, message: '请输入' }] },
                        ]),expression:"[\n                          'contractId',\n                          { rules: [{ required: true, message: '请输入' }] },\n                        ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"工程名称"}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.msg.projectName}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"项目名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'constructionName',
                          { rules: [{ required: true, message: '请输入' }] },
                        ]),expression:"[\n                          'constructionName',\n                          { rules: [{ required: true, message: '请输入' }] },\n                        ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"发出时间","label-col":{ span: 8 },"wrapper-col":{ span: 12 }}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'deliveryTime',
                          { rules: [{ required: true, message: '请选择' }] },
                        ]),expression:"[\n                          'deliveryTime',\n                          { rules: [{ required: true, message: '请选择' }] },\n                        ]"}],staticStyle:{"width":"100%"},attrs:{"format":"YYYY-MM-DD"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"估价(万元)","label-col":{ span: 8 },"wrapper-col":{ span: 12 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'valuation',
                          { rules: [{ required: true, message: '请输入！' }] },
                        ]),expression:"[\n                          'valuation',\n                          { rules: [{ required: true, message: '请输入！' }] },\n                        ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"是否需要核价","label-col":{ span: 12 },"wrapper-col":{ span: 8 }}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'accountingPriceOption',
                          { rules: [{ required: true, message: '请选择' }] },
                        ]),expression:"[\n                          'accountingPriceOption',\n                          { rules: [{ required: true, message: '请选择' }] },\n                        ]"}]},[_c('a-radio',{attrs:{"value":"1"}},[_vm._v(" 是 ")]),_c('a-radio',{attrs:{"value":"0"}},[_vm._v(" 否 ")])],1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"是否符合资金审计原则","label-col":{ span: 12 },"wrapper-col":{ span: 8 }}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'suitAudit',
                          { rules: [{ required: true, message: '请选择' }] },
                        ]),expression:"[\n                          'suitAudit',\n                          { rules: [{ required: true, message: '请选择' }] },\n                        ]"}]},[_c('a-radio',{attrs:{"value":"1"}},[_vm._v(" 是 ")]),_c('a-radio',{attrs:{"value":"0"}},[_vm._v(" 否 ")])],1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"是否需要经决会决策","label-col":{ span: 12 },"wrapper-col":{ span: 8 }}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'meetingComments',
                          { rules: [{ required: true, message: '请选择' }] },
                        ]),expression:"[\n                          'meetingComments',\n                          { rules: [{ required: true, message: '请选择' }] },\n                        ]"}]},[_c('a-radio',{attrs:{"value":"1"}},[_vm._v(" 是 ")]),_c('a-radio',{attrs:{"value":"0"}},[_vm._v(" 否 ")])],1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"情况说明"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'description',
                          { rules: [{ required: true, message: '请输入' }] },
                        ]),expression:"[\n                          'description',\n                          { rules: [{ required: true, message: '请输入' }] },\n                        ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("分包事项申请")]),_c('div',{staticClass:"link-list"},[(_vm.fileList.length !== 0)?_c('a-checkbox-group',{attrs:{"value":_vm.selectedFileList},on:{"change":_vm.onSelectFile}},_vm._l((_vm.fileList),function(item){return _c('div',{key:item.id,staticClass:"link"},[_c('a-checkbox',{attrs:{"value":item.id}},[_c('a',{attrs:{"target":"_blank","download":"","href":item.completePath}},[_vm._v(_vm._s(item.name))])])],1)}),0):(_vm.fileList.length === 0 && _vm.id)?_c('div',{staticStyle:{"color":"#ccc"}},[_vm._v(" 无 ")]):_c('div',{staticStyle:{"color":"#ccc","line-height":"1.5"}},[_vm._v(" 《分包事项申请预算单》 ")]),_c('div',{staticClass:"control-bar"},[_c('FileUpload',{on:{"uploaded":_vm.uploaded}},[_c('div',{staticClass:"control"},[_c('a-icon',{attrs:{"type":"upload"}})],1)]),_c('div',{staticClass:"control",on:{"click":_vm.deleteFile}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)],1)])],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),_c('a-button',{attrs:{"html-type":"submit","type":"primary"}},[_vm._v("提交")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }